<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" offset-md="3">
        <h1 class="font-weight-regular text-center pt-10">Choose a payment method</h1>
        <p class="text-center pb-5">You will not be charged untill you review this order on the next page</p>

        <v-card class="pa-5">
          <v-radio-group v-model="paymentMethod">
            <v-row>
              <v-col class="d-flex justify-start align-center">
                <v-radio>
                  <template v-slot:label>
                    <div>
                      Card
                    </div>
                    <div>
                      <v-btn class="mx-1" outlined color="var(--color-gray)" max-width="50px" height="35px">
                        <v-img contain max-width="40px" src="@/assets/images/master card-01.svg"></v-img>
                      </v-btn>
                      <v-btn class="mx-1" outlined color="var(--color-gray)" max-width="50px" height="35px">
                        <v-img contain max-width="40px" src="@/assets/images/visa-01.svg"></v-img>
                      </v-btn>
                      <v-btn class="mx-1" outlined color="var(--color-gray)" max-width="50px" height="35px">
                        <v-img max-height="30px" max-width="40px" src="@/assets/images/american express-01.svg"></v-img>
                      </v-btn>
                    </div>
                  </template>
                </v-radio>
              </v-col>
            </v-row>
            <v-row v-if="paymentMethod === 0" class="mt-5">
              <v-col cols="12">
			   <ValidationObserver ref="observer">
				<ValidationProvider name="Card Name" rules="required" v-slot="{errors }">
				 <text-input
				   :errors="errors"
				   v-model="credit_card.name"
				   label="Name on card"
				 />
				</ValidationProvider>
				<ValidationProvider name="Card number" rules="required|numeric|digits:16" v-slot="{errors }">
					<text-input
					  :errors="errors"
					  v-model="credit_card.number"
					  label="Number on card"
					/>
				</ValidationProvider>
				<ValidationProvider name="ex. Month" rules="required|cart_month" v-slot="{errors}">
					 <text-input
					   :errors="errors"
					   v-model="credit_card.ex_month"
					   label="Month on card"
					 />
				</ValidationProvider>
				<ValidationProvider name="ex. Year" rules="required|cart_year" v-slot="{errors}">
					 <text-input
					   :errors="errors"
					   v-model="credit_card.ex_year"
					   label="Year on card"
					 />
				</ValidationProvider>
				<ValidationProvider name="Card CSV" rules="required|numeric|digits:3" v-slot="{errors}">
					 <text-input
					   :errors="errors"
					   v-model="credit_card.cvs"
					   type="password"
					   label="CSV on card"
					 />
				</ValidationProvider>
			   </ValidationObserver>

              </v-col>
            </v-row>
          </v-radio-group>


        </v-card>
	   <state-indicate v-slot:default="{usePromise, isLoading}">
        <v-btn
		  :loading="isLoading"
          x-large
          @click="usePromise(sendCard())"
          width="100%"
          color="primary"
          class="mt-3 rounded-lg"
        >Review your order
        </v-btn>
	   </state-indicate>
      </v-col>


    </v-row>
    <v-row class="my-15"></v-row>

  </v-container>
</template>

<script>
import {mapState, mapActions} from "vuex";
import TextInput from '@/components/reusable/TextInput'
// import CheckoutSteeperHeader from '@/components/utils/CheckoutSteeperHeader'
import api from '../../api'
import {extend} from 'vee-validate';


function getPattern(dt) {
  let y = (dt.getFullYear() + '').split('');
  let patternStr = `^(20[${y[2]}-9][${y[3]}-9]$`;
  return new RegExp(patternStr);
}

extend('digits', (value, digits) => {
  if (value.length === digits.length) {
    return true;
  }

  return `This value must be a ${digits.length} digits`;
});

extend('cart_month', (value) => {
  if (/^(0?[1-9]|1[012])$/.test(value)) {
    return true;
  }

  return `This value must be a month 01-12`;
});

extend('cart_year', (value) => {
  console.log(/\d{4}/.test(value))
  const dt = new Date();
  if (/\d{4}/.test(value)) {
    let y = (dt.getFullYear() + '').split('');
    const regexp = `(20\[${y[2]}-9\]\[${y[3]}-9\])`;
    if (new RegExp('^' + regexp + '$').test(value)) {
      return true;
    }
  }


  return `This value must be a year from ` + dt.getFullYear();
});



export default {
  name: "Payment",
  components: {
    TextInput
  },
  data: () => ({
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    credit_card:{
      name: null,
      number: null,
      ex_month: null,
      ex_year: null,
      cvs: null,
    },

    paymentMethod: 0,
    apiUrl: '',

  }),
  computed: {
    ...mapState({
      steep: state => {
        return state.cart.steep
      },
      payment: state => {
        return state.cart.payment
      },
    })
  },

  mounted() {
    this.apiUrl = process.env.VUE_APP_API_URL
    Object.keys(this.payment).forEach((key) => {
      if (typeof this.payment[key] !== 'undefined') {
        this.credit_card[key] = this.payment[key]
	  }
	})
  },
  methods: {
    ...mapActions({
      setSteep: 'cart/setSteep',
      setPayment: 'cart/setPayment',
    }),
    async sendCard() {
	  if (await this.$refs.observer.validate()) {
        await this.setPayment(this.credit_card).finally(() => this.setSteep(1))
	  }
    },

  }
}
</script>

<style scoped lang="scss">
h1 {
  font-size: 2.7em;
}

::v-deep .v-radio {
  width: 100%;

  .v-label {
    font-weight: bolder;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
  }
}
</style>
