<template>
  <v-container class="px-0">
    <v-row no-gutters>
      <v-col cols="3" md="1" class="px-0 mx-0">
        <v-img class="rounded" style="max-width: 270px" :src="product.image_url"/>
      </v-col>
      <v-col cols="9">
        <div class="pl-5">
          <h3>
            {{ product.name }}
          </h3>
          <div class="ISBN">{{ product.isbn || 'ЗАВ 170/БН2-062017/32' }}</div>
          <v-row no-gutters justify="space-between" class="mt-5">
            <v-col cols="5">
              Quantity: {{quantity}}
            </v-col>
            <v-col cols="5">
              <div  class="mt-1">
                <div class="price">R {{ product.price }}</div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>

    </v-row>
    <v-divider></v-divider>

  </v-container>
</template>

<script>
export default {
  name: "CardItem",
  props: ['product'],
  data() {
    return {
      quantity: 1,
    }
  }
}
</script>

<style scoped lang="scss">
.ISBN {
  color: var(--color-gray);
  &:before {
    content: "ISBN: ";
    font-weight: bold;
  }
}

.price {
  font-size: 1.2em;
  font-weight: bold;
}

.old_price {
  font-size: .7em;
}

::v-deep .v-text-field--outlined fieldset {
  border: 2px solid var(--color-blue);
}
</style>
