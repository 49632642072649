<template>
    <v-text-field
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      :rules="rules || []"
      :label="label || null"
      :type="type || 'text'"
      class="rounded-lg"
      :required="required"
      dense
      outlined
      :placeholder="placeholder || null"
      height="60"
	  autocomplete="new-password"
	  :error-messages="errors"
    ></v-text-field>

</template>

<script>
export default {
  name: "TextInput",
  props: ['value', 'rules','label','placeholder','type','required', 'errors'],
}
</script>

<style scoped>

</style>
