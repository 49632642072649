<template>
  <v-container>
    <v-row justify="center">
      <v-card
        width="360px"
        class="d-flex flex-column align-center"
      >
        <div class="pa-5"></div>

        <v-img max-width="33px" src="@/assets/images/logo_small_colored.svg"></v-img>

        <h1>Thank You!</h1>
        <div class="pa-8"></div>
        <hr class="divider">
        <p class="font-weight-bold py-5">We hope you enjoy your purchase.</p>
        <router-link to="/instruction" class="pb-5"> Instructions for downloading</router-link>
        <hr class="divider">
        <div class="pa-1"></div>
        <p class="font-weight-bold text--secondary"><small>Find us at:</small></p>
        <v-btn class="text-capitalize font-weight-bold">
          <v-icon   >mdi-instagram</v-icon>
          Instagram/DigiThis
        </v-btn>
        <v-btn class="text-capitalize font-weight-bold my-5">
          <v-icon  color="#1da1f2">mdi-twitter</v-icon>
          Twitter/DigiThis
        </v-btn>
        <v-btn class="text-capitalize font-weight-bold">
          <v-icon  color="#3c5a9a">mdi-facebook</v-icon>
          Facebook/DigiThis 
        </v-btn>
        <v-row class="my-5"></v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ThankYou"
}
</script>

<style scoped>
.divider {
  width: 90%;
  margin: 0 auto;
  /*color: rgba(var(--color-gray),.5);*/
  border-color: rgba(var(--color-gray), .5);
}
</style>
