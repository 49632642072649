<template>
  <div class="white">

    <v-container class="mt-0 mt-md-7">
      <v-row>
        <v-col>
<!--          <template v-if="steep === 0">-->
<!--            <delivery/>-->
<!--          </template>-->
          <template v-if="steep === 0">
            <payment/>
          </template>
          <template v-if="steep === 1">
            <review/>
          </template>
        </v-col>
      </v-row>
    </v-container>


  </div>
</template>

<script>
import {mapState} from "vuex";
import Delivery from "@/views/order/Delivery";
import Payment from "@/views/order/Payment";
import Review from "@/views/order/Review";

export default {
  name: "CheckoutWrapper",
  components: {Delivery, Payment, Review},
  data: () => ({
    valid: false,
  }),
  computed: {
    ...mapState({
      steep: state => {
        return state.cart.steep
      }
    })
  }

}
</script>

<style scoped>

</style>
