<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" offset-md="3">
        <h1 class="font-weight-regular text-center pt-10 pb-5">Enter your billing address</h1>

        <text-input
          v-model="address.email"
          :rules="emailRules"
          label="E-mail"
        />
        <text-input
          v-model="address.phone"
          :rules="phoneRules"
          type="tel"
          label="Phone"
        />
        <text-input
          v-model="address.country"
          label="Country"
          :rules="[v => !!v || 'Country is required']"
        />
        <text-input
          v-model="address.fullname"
          label="Full Name"
          :rules="[v => !!v || 'Name is required',v => /\w\s\w/.test(v) || 'Full Name must be valid',]"
        />
        <text-input
          v-model="address.city"
          label="City"
          :rules="[v => !!v || 'City is required']"
        />

        <v-row>
          <v-col cols="12" md="6" class="pb-0 py-md-3">
            <text-input
              v-model="address.zip"
              label="Zip / Postal Code"
              :rules="[v => !!v || 'Zip / Postal Code is required']"
            />
          </v-col>
          <v-col cols="12" md="6" class="py-0 py-md-3">
            <text-input
              v-model="address.state"
              label="State"
            />
          </v-col>
        </v-row>
        <v-btn
          x-large
          @click="validate"
          width="100%"
          color="primary"
          class="mt-3 rounded-lg"
        >Continue to payment
        </v-btn>
      </v-col>

      <v-col cols="12" v-if="false">
        <v-list v-if="cartItems.length">
          <v-list-item v-for="(product, key) in cartItems" :key="key">
            <v-list-item-avatar>
              <img :src="product.image_url"/>
            </v-list-item-avatar>
            <v-row>
              <v-col class="pb-0 pt-0" cols="12">
                <v-list-item-title>{{ product.name }}</v-list-item-title>
              </v-col>
              <v-col class="pb-0 pt-0" cols="12">
                <v-list-item-subtitle>Price: R {{ product.price }}</v-list-item-subtitle>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col v-if="false">
        <div>Test form successfully generated please click on "Place order" for payment.</div>
        <form :action="apiUrl +'/api/orders'" method="post">
          <input type="hidden" name="payment_gateway" value="PayFast">
          <input type="hidden" name="item_name" value="Test">
          <input type="hidden" name="description" value="Test">
          <input type="hidden" name="pay_method" value="card">
          <input type="hidden" name="payment_method_attrs[number]" value="4242424242424242">
          <input type="hidden" name="payment_method_attrs[expiryMonth]" value="6">
          <input type="hidden" name="payment_method_attrs[expiryYear]" value="2030">
          <input type="hidden" name="payment_method_attrs[cvv]" value="123">
          <input type="hidden" name="" value="">

          <v-btn type="submit">Place Order</v-btn>

        </form>
      </v-col>

    </v-row>
    <v-row class="my-15"></v-row>

  </v-container>
</template>

<script>
import {mapState, mapActions} from "vuex";
import TextInput from '@/components/reusable/TextInput'
// import CheckoutSteeperHeader from '@/components/utils/CheckoutSteeperHeader'
import api from '../../api'

export default {
  name: "Delivery",
  components: {TextInput},
  data: () => ({
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    phoneRules: [
      v => !!v || 'Phone number is required',
      v => /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/.test(v) || 'Phone number must be valid',
    ],
    address: {
      email: '',
      phone: '',
      country: '',
      fullname: '',
      addressline: '',
      city: '',
      zip: '',
      state: '',
    },
    email: '',
    apiUrl: '',

  }),
  computed: {
    ...mapState({
      cartItems: state => {
        return state.cart.items;
      },
      steep: state => {
        return state.cart.steep
      }
    })
  },

  mounted() {
    this.apiUrl = process.env.VUE_APP_API_URL
    // this.setSteep(1)
  },
  methods: {
    ...mapActions({
      setSteep: 'cart/setSteep',
      setDelivery: 'cart/setDelivery',
    }),
    validate(){
      this.setDelivery(this.address)
      this.setSteep(1)
    },

  }
}
</script>

<style scoped>
h1{
  font-size: 2.7em;
}

</style>
