<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="font-weight-regular text-left pa-0 pb-md-10">
          <span class="d-none d-md-inline">Review and submit your order</span>
          <small class="d-inline d-md-none">Review and submit your order</small>
        </h1>
      </v-col>

      <v-col cols="12" md="8">
        <v-row>
<!--          <v-col cols="12" md="6">-->
<!--            <v-divider v-show="$vuetify.breakpoint.smAndDown" class="pb-5"></v-divider>-->
<!--            <v-row>-->
<!--              <v-col cols="6">-->
<!--                <div class="font-weight-bold">Shipping Address</div>-->
<!--              </v-col>-->
<!--              <v-col cols="6">-->
<!--                <div class="font-weight-bold change text-right text-md-left" @click="setSteep(0)">-->
<!--                  Change-->
<!--                </div>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--            <v-row>-->
<!--              <v-col cols="12">-->
<!--                <p>{{ delivery.fullname }}</p>-->
<!--                <p>{{ delivery.addressline }}</p>-->
<!--                <p>{{ delivery.city }} {{ delivery.state }} {{ delivery.country }} {{ delivery.zip }} </p>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-col>-->
          <v-col cols="12" md="6">
            <v-divider v-show="$vuetify.breakpoint.smAndDown" class="pb-5"></v-divider>

            <v-row>
              <v-col cols="6">
                <div class="font-weight-bold">Payment method</div>
              </v-col>
              <v-col cols="6">
                <div class="font-weight-bold change text-right text-md-left" @click="setSteep(0)">
                  Change
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="d-flex align-center">
                <v-btn class="mx-1" outlined color="var(--color-gray)" max-width="50px" height="35px">
                  <v-img v-if="cardBrand === 'visa'" contain max-width="40px" src="@/assets/images/visa-01.svg"></v-img>
				 <v-img v-if="cardBrand === 'master_card'" contain max-width="40px" src="@/assets/images/master card-01.svg"></v-img>
				 <v-img v-if="cardBrand === 'american_express'" max-height="30px" max-width="40px" src="@/assets/images/american express-01.svg"></v-img>
                </v-btn>
              </v-col>
              <v-col cols="10" class="d-flex align-center">
                <div class="ml-4">
                  <p>{{ payment.name }} ...{{ payment.number.slice(-4) }}</p>
                  <p>Exp: {{ payment.ex_month }}/{{ payment.ex_year }}</p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4"  order="2" order-md="1">
        <v-card rounded class="rounded-xl pa-3">
          <v-container>
            <v-row>
              <v-col cols="12" class="d-flex justify-space-between">
                <div>Item(s) total</div>
                <div>R {{ totalAmount }}</div>
              </v-col>
<!--              <v-col cols="12" class="d-flex justify-space-between">-->
<!--                <div>Shipping</div>-->
<!--                <div>R 5.39</div>-->
<!--              </v-col>-->
            </v-row>
            <v-divider class="my-5"></v-divider>
            <v-row>
              <v-col cols="12" class="d-flex justify-space-between font-weight-bold">
                <div>Total ({{ cartItems.length}} items)</div>
                <div>R {{ totalAmount }}</div>
              </v-col>
            </v-row>

            <v-row class="my-3">
              <v-col cols="12">
			   <form :action="orderApiUrl" method="post">
				<input type="hidden" name="payment_gateway" value="PayFast">
				<input type="hidden" name="item_name" value="DigiThis">
				<input type="hidden" name="description" value="DigiThis">
				<input type="hidden" name="pay_method" value="card">
				<input type="hidden" name="payment_method_attrs[number]" :value="payment.number">
				<input type="hidden" name="payment_method_attrs[expiryMonth]" :value="payment.ex_month">
				<input type="hidden" name="payment_method_attrs[expiryYear]" :value="payment.ex_year">
				<input type="hidden" name="payment_method_attrs[cvv]" :value="payment.cvs">
				<input type="hidden" name="" value="">

				<v-btn
				  type="submit"
				  width="100%"
				  x-large
				  color="primary"
				>submit order</v-btn>

			   </form>
<!--			   <state-indicate v-slot:default="{usePromise, isLoading}">-->
<!--				-->
<!--			   </state-indicate>-->
              </v-col>
            </v-row>



            <v-row>
              <v-col cols="12">
                <div class="descr">
                  By clicking Submit Order, you authorize DigiThis to process your order and agree to DigiThis’s Terms
                  of
                  Use and Privacy Policy.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="12" class="pa-0 ma-0" order="1" order-md="2">
        <v-list v-if="cartItems.length" class="pa-0 ma-0">
          <v-list-item v-for="(product, key) in cartItems" :key="key" class="pa-0 ma-0">
            <card-item :product="product"/>
            <!--            <v-list-item-avatar>
                          <img :src="product.image_url"/>
                        </v-list-item-avatar>
                        <v-row>
                          <v-col class="pb-0 pt-0" cols="12">
                            <v-list-item-title>{{ product.name }}</v-list-item-title>
                          </v-col>
                          <v-col class="pb-0 pt-0" cols="12">
                            <v-list-item-subtitle>Price: R {{ product.price }}</v-list-item-subtitle>
                          </v-col>
                        </v-row>-->
          </v-list-item>
        </v-list>
        <v-card-text class="text-center" v-else>
          Your shopping cart is empty.
        </v-card-text>
      </v-col>
    </v-row>
    <v-dialog
      v-model="thankYouDialog"
      width="360px"
    >
      <thank-you  @close-dialog="'thankYouDialogClose'" />
    </v-dialog>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import CardItem from "@/views/order/CardItem";
import ThankYou from '@/views/order/ThankYou'
import OrdersApi from '@/api/orders';

export default {
  name: "Review",
  components: {ThankYou, CardItem},
  data: () => ({
    thankYouDialog: false,
	orderApiUrl: process.env.VUE_APP_API_URL + '/api/orders'
  }),
  computed: {
    ...mapState({
      steep: state => {
        return state.cart.steep
      },
      cartItems: state => {
        return state.cart.items;
      },
      items: state => {
        return state.cart.items
      },
      delivery: state => {
        return state.cart.delivery
      },
      payment: state => {
        return state.cart.payment
      },
    }),
	cardBrand() {
      if (this.payment.number) {
        if (
            /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(this.payment.number)
        ) {
          return 'master_card';
        }

        if (
            /^4[0-9]{12}(?:[0-9]{3})?$/.test(this.payment.number)
        ) {
          return 'visa';
        }

        if (
            /^3[47][0-9]{13}$/.test(this.payment.number)
        ) {
          return 'american_express';
        }
	  }

      return null;
	},
    totalAmount() {
      let sum = 0;
      this.cartItems.forEach((item) => {
        sum += parseInt(item.price);
      });
      return sum;
    }
  },
  methods: {
    ...mapActions({
      setSteep: 'cart/setSteep'
    }),
    change(target) {
      window.console.log(target);
    },
	async sendOrder() {
      await OrdersApi.store(formData);
	}
  }
}
</script>

<style scoped>
h1 {
  font-size: 2.7em;
}

::v-deep p {
  margin-bottom: 0;
}

.change {
  color: var(--color-blue);
  cursor: pointer;
}

.descr {
  font-size: .9em;
  color: var(--color-gray);
}
</style>
