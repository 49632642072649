import axios from "axios";

export default {
  update(id, data) {
    return axios.post(`/api/orders/${id}`, data)
  },
  store(data) {
    return axios.post(`/api/orders`, data)
  },
  delete(id) {
    return axios.delete(`/api/orders/${id}`)
  },
  authUserOrders(page) {
    return axios.get('/api/orders/auth', {
      params: {
        page: page
      }
    })
  }
}
